<template>
  <b-table
    hover
    :busy="busy"
    :items="item.payments"
    :fields="fieldsList"
    responsive
    :sticky-header="'calc(100vh - 330px)'"
  >
    <!-- Column: User -->
    <template #cell(user)="{ item }">
      <b-media
        class="d-flex align-items-center pl-1 ml-25 mt-25"
        style="min-width: 150px"
      >
        <template>
          <b-link
            class="text-dark d-flex align-items-center"
            @click="fetchMembers(item.paidByMember)"
          >
            <div>
              <user-avatar 
                v-if="item.paidByMember"
                :user="item.paidByMember" 
                size="24" 
              />
              <user-avatar 
                v-else
                :user="{}" 
                size="24" 
              />
            </div>
            <div class="ml-50">
              <p v-if="item.paidByMember" class="font-weight-extrabold mb-0">
                {{ `${item.paidByMember.name} ${item.paidByMember.surname}` }}
              </p>
              <span v-else class="text-muted">---</span>
            </div>
          </b-link>
        </template>
      </b-media>
    </template>

    <!-- Column: sender -->
    <!--template #cell(sender)="{ item }">
      <b-link :href="getCollectiveUrl(item.emitter)" target="_blank">
        <div
          class="text-dark w-100 h-100 d-flex pl-1 ml-50 align-items-center"
          style="min-width: 150px"
        >
          <p class="mb-0">
            {{ getTranslatedName(item.emitter) }}
          </p>
        </div>
      </b-link>
    </template-->

    <!-- Column: collector -->
    <template #cell(collector)="{ item }">
      <b-link :href="getCollectiveUrl(item.collector)" target="_blank">
        <div
          class="text-dark w-100 h-100 d-flex pl-1 ml-50 align-items-center"
          style="min-width: 150px"
        >
          <p class="mb-0">
            {{ getTranslatedName(item.collector) }}
          </p>
        </div>
      </b-link>
    </template>

    <!-- Column: community -->
    <!--template #cell(community)="{ item }">
      <b-link :href="getCollectiveUrl(item.community)" target="_blank">
        <div
          class="text-dark w-100 h-100 d-flex pl-1 ml-50 align-items-center"
          style="min-width: 150px"
        >
          <p class="mb-0">
            {{ getTranslatedName(item.community) }}
          </p>
        </div>
      </b-link>
    </template-->

    <!-- Column: total price after discounts -->
    <template #cell(price)="{ item }">
      <div
        class="w-100 h-100 d-flex align-items-center justify-content-end"
        style="min-width: 120px"
      >
        <p class="mb-0">
          {{ item.price.toFixed(2) }}
        </p>
      </div>
    </template>

    <!-- Column: price without discounts -->
    <template #cell(real)="{ item }">
      <div
        class="w-100 h-100 d-flex align-items-center justify-content-end"
        style="min-width: 120px"
      >
        <p v-if="item.hasDiscount" class="mb-0">
          {{ (item.price + item.discountAbsolute).toFixed(2) }}
         
        </p>
        <p v-else>{{ item.price.toFixed(2) }}</p>
      </div>
    </template>

    <!-- Column: discount -->
    <template #cell(discount)="{ item }">
      <div
        class="w-100 h-100 d-flex align-items-center justify-content-end"
        style="min-width: 120px"
      >
        <p v-if="item.hasDiscount" class="mb-0">
          - {{ item.discountAbsolute.toFixed(2) }}
        </p>
        <p v-else class="mb-0">---</p>
      </div>
    </template>

    <!-- Column: Currency -->
    <template #cell(currency)="{ item }">
      <div class="w-100 h-100 d-flex pl-1 ml-50 align-items-center">
        <p>
          {{ getCurrencyLabel(item.currency) }}
        </p>
      </div>
    </template>

    <!-- Column: Reference -->
    <template #cell(ref)="{ item }">
      <div class="w-100 h-100 d-flex pl-1 ml-50 align-items-center">
        <p>{{ item.ref }}</p>
      </div>
    </template>

    <!-- Column: Issuing Date -->
    <!--template #cell(date)="{ item }">
      <div
        class="w-100 h-100 d-flex pl-1 ml-50 align-items-center"
        style="min-width: 160px"
      >
        <p class="mb-0">
          {{ getTransactionDate(item.date) }}
        </p>
      </div>
    </template-->

    <!-- Column: Due Date -->
    <!--template #cell(duedate)="{ item }">
      <div
        class="w-100 h-100 d-flex pl-1 ml-50 align-items-center"
        style="min-width: 160px"
      >
        <p class="mb-0">
          {{ getTransactionDate(item.dueDate) }}
        </p>
      </div>
    </template-->

    <!-- Column: Date Creation -->
    <template #cell(createdat)="{ item }">
      <div
        class="w-100 h-100 d-flex pl-1 ml-50 align-items-center"
        style="min-width: 160px"
      >
        <p class="mb-0">
          {{ getTransactionDate(item.createdAt) }}
        </p>
      </div>
    </template>

    <!-- Column: Status -->
    <template #cell(status)="{ item }">
      <div class="d-flex align-items-center">
        <b-badge
          v-if="item.status"
          pill
          :variant="getColor(item.status)"
          class="text-capitalize mb-0 ml-1 mt-75 check-button"
        >
          {{ getStatus(item.status) }}
        </b-badge>
        <p v-else class="text-muted mb-0 ml-2 pl-75 mt-75">---</p>
      </div>
    </template>

    <!-- Column: Actions -->
    <template #cell(actions)="{ item }">
      <div class="d-flex align-items-center">
        <!-- Edit -->
        <feather-icon icon="Edit2Icon" size="18" class="text-primary ml-2 cursor-pointer" @click="handleEdit(item)" disabled />
        <!-- Edit item modal -->
        <b-modal
          id="edit-payment-form"
          v-model="isModalEditOpened"
          :title="$t('backoffice.payments.edit')"
          :ok-title="$t('form.actions.save')"
          ok-only
          @ok="saveEditedTransaction()"
        >
          <div>Coming soon... work in progress.</div>
        </b-modal>

        <!-- Delete -->
        <feather-icon icon="Trash2Icon" size="18" class="text-primary cursor-pointer ml-1" @click="handleDelete(item)" />
        <!-- Delete item modal -->
        <unshare-item-modal
          v-model="isDeleteModalVisible"
          model-type="payment"
          @remove-item="deleteItem(payment.key)"
          @modal-invisible="isDeleteModalVisible = false"
        />

        <!-- Download files -->
        <!--feather-icon 
          icon="DownloadIcon" 
          size="18" 
          class="text-primary cursor-pointer ml-1" 
          @click="handleDownloadFiles(item)" 
          :id="'download-payemnt-button-'+item.key" 
        /-->
        <a
          v-if="item.fileURL"
          class="ml-1"
          :href="'https://uploadscdn.nectios.com/'+item.fileURL"
          target="_blank"
        >
          <feather-icon icon="DownloadIcon" size="20" />
        </a>
      </div>
    </template>

    <!-- TOTALS -->
    <!-- Table footer for the totals -->
    <template v-if="showTotals" #custom-foot="{ columns }">
      <b-td v-for="columnIndex in columns" :key="columnIndex" class="totals">
        <p
          v-if="columnIndex === 1"
          class="font-weight-extrabold mb-0 font-medium-2 ml-2 text-white"
        >
          Total
        </p>
        <div
          v-if="[4, 5, 6].includes(columnIndex)"
          class="w-100 h-100 d-flex align-items-center justify-content-end"
        >
          <p v-if="columnIndex === 4" class="mb-0 text-white">
            {{ item.totals.real.toFixed(2) }}
            {{ item.payments[0].currency.symbol }}
          </p>
          <p v-if="columnIndex === 5" class="mb-0 text-white">
            - {{ item.totals.discount.toFixed(2) }}
            {{ item.payments[0].currency.symbol }}
          </p>
          <p v-if="columnIndex === 6" class="mb-0 text-white">
            {{ item.totals.price.toFixed(2) }}
            {{ item.payments[0].currency.symbol }}
          </p>
        </div>
      </b-td>
    </template>
  </b-table>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import moment from "moment";
import UserAvatar from "@core/components/user/UserAvatar.vue";
import { getCollectiveUrl } from "@core/utils/collective";
import UnshareItemModal from "@/@core/components/modal/UnshareItemModal.vue";

export default {
  components: {
    UserAvatar,
    UnshareItemModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    busy: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
			isModalEditOpened: false,
			isDeleteModalVisible: false,
			currentItem: null,
			payment: {},
    }
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    fieldsList() {
      return [
        {
          key: "ref",
          label: this.$t("backoffice.payments.fields.ref"),
          sortable: true,
        },
        {
          key: "user",
          label: this.$t("backoffice.payments.fields.paid-by"),
          sortable: true,
        },
        // {
        //   key: "sender",
        //   label: this.$t("backoffice.payments.fields.sender"),
        //   sortable: true,
        // },
        {
          key: "collector",
          label: this.$t("backoffice.payments.fields.receptor"),
          sortable: true,
        },
        // {
        //   key: "community",
        //   label: this.$t("backoffice.payments.fields.space"),
        //   sortable: true,
        // },
        {
          key: "real",
          label: this.$t("backoffice.payments.fields.real-price"),
          sortable: true,
        },
        {
          key: "discount",
          label: this.$t("backoffice.payments.fields.discount"),
          sortable: true,
        },
        {
          key: "price",
          label: this.$t("backoffice.payments.fields.final-price"),
          sortable: true,
        },
        {
          key: "currency",
          label: this.$t("backoffice.payments.fields.currency"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("backoffice.payments.fields.status"),
          sortable: true,
        },
        // {
        //   key: "date",
        //   label: this.$t("backoffice.payments.fields.issuing-date"),
        //   sortable: true,
        // },
        // {
        //   key: "duedate",
        //   label: this.$t("backoffice.payments.fields.due-date"),
        //   sortable: true,
        // },
        {
          key: "createdat",
          label: this.$t("backoffice.payments.fields.date"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("backoffice.payments.fields.actions"),
          sortable: true,
        },
      ];
    },
    showTotals() {
      return true;
    }
  },
  methods: {
    async fetchMembers(member) {
      const response = await this.$store.dispatch("getItems", {
        itemType: "communityMembers",
        forceAPICall: true,
        requestConfig: {
          page: 1,
          perPage: 10,
          username: member.username,
        },
      });

      if (response.data?.length > 0) {
        const route = this.$router.resolve({
          name: "backoffice-members-details",
          params: {
            username: member.username,
            communityId: this.currentCollective.slug,
          },
        });
        window.open(route.href, "_blank");
        return;
      }
      const route = this.$router.resolve({
        name: "backoffice-members-details",
        params: {
          username: member.username,
          communityId: this.currentCollective.parentCollective.slug,
        },
      });
      window.open(route.href, "_blank");
    },
    translate(field) {
      // console.log('Trnaslating field:', field)
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field
      );
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format("DD MMM YYYY - HH:mm");
    },
    getStatus(payment) {
      const statusPayment = {
        pending: this.$t("backoffice.members.pending"),
        succeeded: this.$t("backoffice.members.confirmed"),
        failed: this.$t("backoffice.members.confirmed"),
      };
      return statusPayment[payment];
    },
    getCollectiveUrl(collective) {
      return `${getCollectiveUrl(collective)}/welcome`;
    },
    getTranslatedName(item) {
      if(!item){
        return '---';
      }
      return this.translate(item.name);
    },
    getColor(payment) {
      const color = {
        pending: "light",
        succeeded: "light-success",
        failed: "light-danger",
      };
      return color[payment];
    },
    getTransactionDate(date){
      if(!date){
        return '---';
      }
      return this.timestampToTime(date);
    },
    getCurrencyLabel(currency) {
      if(!currency){
        return 'EUR (€)';
      }
      return currency.ISO + ' (' + currency.symbol + ')';
    },
		handleEdit(item) {
      console.log('handleEdit item', item);
			this.currentItem = { ...item };
			delete this.currentItem.key;
			delete this.currentItem.ref;
			delete this.currentItem.date;
			this.payment = item;
			this.isModalEditOpened = true;
      //TODO: emit edit with currentItem
		},
		handleDelete(item) {
      console.log('handleDelete item', item);
			this.currentItem = { ...item };
			this.payment = item;
			this.isDeleteModalVisible = true;
      console.log('this.currentItem', this.currentItem)
      console.log('Opening delete modal...')
		},
		async deleteItem(key) {
      console.log('deleting item with key:', key)
			try {
				await this.$store.dispatch("editItem", {  // para que sea PUT
					item: {
						itemType: "deletePayment",
						requestConfig: {
							paymentKey: key,
						},
					},
				});
			} catch {}
			this.currentItem = null;
		},
		handleDownloadFiles(item){
			//console.log('this.hiddenLinks', this.hiddenLinks);
			//e.preventDefault();

			// if(!this.hiddenLinks[item]){
			// 	return 0;
			// }
			// this.hiddenLinks[item].forEach(url => {
			// 	console.log('url to open:', url);
    	// 		window.open(url);
			// });
		},
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes
.totals {
  background-color: $primary;
}
.download-button {
  background-color: white !important;
  color: #28a9b8 !important;
  border: none;
}
</style>
